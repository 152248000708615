
@import "~bootstrap/dist/css/bootstrap.css";
@import "font-awesome.min.css";
@import "linearicons.css";
@import "owl.carousel.css";


@font-face {
    font-family: Montserrat-Black;
    src: url("../fonts/Montserrat-Black.ttf");
}

@font-face {
    font-family: Montserrat-Bold;
    src: url("../fonts/Montserrat-Bold.ttf");
}

h1, h2, h3,
h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    color: #222222;
    line-height: 1.2em !important;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 600;
}

.h1, .h2, .h3,
.h4, .h5, .h6 {
    margin-bottom: 0;
    margin-top: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #222222;
}

h1, .h1 {
    font-size: 26px;
}

h2, .h2 {
    font-size: 30px;
}

h3, .h3 {
    font-size: 24px;
}

h4, .h4 {
    font-size: 18px;
}

h5, .h5 {
    font-size: 16px;
}

h6, .h6 {
    font-size: 14px;
    color: #222222;
}


body {
    color: #777777;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.625em;
    position: relative;
}

.hidden{
    display: none!important;
}

h1,h3{
    font-weight: bolder;
}

#mobile-nav-toggle,#close-menu{
    display: none;
}

.fixed-scroll{
    transition: all 0.5s;
    box-shadow: -21.213px 21px 30px 0 rgba(158, 158, 158, 0.3);
    background: #ffffff;
}

.fixed{
    transition: all 0.5s;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    border:none!important;
}

#menu-navbar{
    -webkit-transition: top 1s;
    -moz-transition: top 1s;
    transition: top 1s;
    right: 0!important;
}

.navbar-header nav ul li{
    display: inline-block;
    padding-left: 20px;
    text-transform: uppercase;
    color: black;
}

.item-menu{
    font-weight: 500;
    font-size: 12px;
    font-family: Montserrat-Bold, sans-serif;
    outline: none;
    color: #222;
}

.navbar-header nav li a:hover{
    color: #4dbdc3;
}

a:hover{
    text-decoration: none;
}

#adv_logo_1, #logo-side{
    display: none;
}

#adv_logo{
    margin-left: 0;
}

#menu-navbar{
    padding-left: 0!important;
}

/*  ================================== */
/*  Body Styles
/*  ================================== */
#content_body{
    min-height: 500px;
    margin: auto;
    overflow-x: hidden;
    padding-right: 0!important;
    padding-left: 0!important;
}
.justify{
    text-align: justify;
}

p{
    font-weight: lighter;
}

.img-fluid-responsive{
    display: none;
}

.section-gap{
    padding-top: 90px!important;
}

/*accueil section*/
#accueil{
    min-height: 500px;
    padding-left: 0!important;
    padding-right: 0!important;
}

.intro_1{
    position: absolute;
    z-index: 1;
    display: none;
    top: 250px;
    vertical-align: middle;
}

.intro h1, .intro_1 h1{
    font-size: 2.3rem;
}

.intro{
    padding-top: 125px;
    text-align: center;
    vertical-align: middle;
}

.boost{
    width:100%;
}

#rcorner{
    border-radius: 50px;
    background: #1fc1c1;
    color: #fff;
    width: 150px;
    height: 40px;
    border: none;
}

.banner-left{
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
    background-size: contain;
    background: url("../img/banner-l.png") no-repeat;
    min-height: 500px;
}

.banner-right{
    text-align: center;
}

/*end accueil section*/

/*About Section */
#home-about-right{
    background-image: url("../img/banner-l.png");
    background-size:auto;
    padding-left: 40px;
    padding-right: 40px;
}

#history-about-left{
    background-image: url("../img/banner-l.png")!important;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
}


#home-about-left{
    padding-left: 0!important;
    padding-right: 0!important;
}

/* end About Section */


/*Services Styles*/
.services-area{
    padding-bottom: 50px;
    padding-left: 0!important;
    padding-right: 0!important;
}

.services-area .justify{
    padding-right: 10px;
    padding-left: 10px;
}

.single-services{
    text-align: center;
    padding-top: 50px;
}

.single-services span, .single-services a h4{
    color:#007bff!important;
}

.single-services a:hover{
    cursor:default;
    color:#007bff;
}

.single-services .lnr {
    font-size: 35px;
    color: #222;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

/*end Services Styles*/

/* produits styles*/
.produit_list{
    text-align: left;
}

.produit_list li{
    list-style: none;
    padding-top: 10px;
}

.check{
    width: 40px;
}

.note{
    color: #007bff;
    font-style: italic;
}

.niveau:hover{
    color:#ffffff;
}

.trophy .niveau:hover{
    color: #ffffff!important;
}

#niveau1 .single-services, #niveau2 .single-services{
    padding-left: -20px;
}

/*end produits styles*/

/*--------- start brand Area -------------*/
.brands-area {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url("../img/partners-bg.png");
}

.brands-area .container{
    height: 220px;
}

.partner{
    padding-top: 50px;
    color: #FFFFFF;
    text-transform: uppercase;
}

.carousel-item{
    width:100px!important;
    text-align: center;
    min-height: 109px;
}

.carousel-item a{
    color: #FFFFFF!important;
    min-height: 120px;
}

.carousel-item a img{
   min-width: 150px;
}

.brand-wrap h2{
    color: #FFFFFF;
    padding-top: 15px;
    padding-bottom: 50px;
}

.partner_nom{
    font-family: Montserrat-Bold, sans-serif;
    height: 25px;
}

/*--------- end brand Area -------------*/


/*************tid****************/
#tid{
    vertical-align: middle;
    width: 80%;
    margin: auto;
}

#tid_list li{
    display: inline-block;
    list-style: none;
}

.text-center{
    text-align: center;
}

.area{
    padding-bottom: 50px;
}
.area button{
    border-radius: 0!important;
    text-align: left;
}

.collapse{
    width: 100%;
}

/*************end tid****************/

/*  ================================== */
/*  End Body Styles */
/*  ================================== */


/* =================================== */
/*  Footer Styles */
/* =================================== */
footer{
    background: black;
    color: #ffffff;
    padding: 0 30px 25px;
}

footer p{
    color: #C9C9C9;
}
.footer-section{
    padding-top: 50px;
}

#copyright{
    margin-top: 25px;
}

.footer-area {
    background-color: #0f1a1a;
}

.single-footer-widget h4 {
    color: #fff !important;
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 18px!important;
}

.single-footer-widget .form-control {
    border: none;
    width: 80% !important;
    font-weight: 300;
    background: #f9f9ff;
    padding-left: 20px;
    border-radius: 0;
    font-size: 14px;
    line-height: normal !important;
    color: #222;
}

.single-footer-widget .form-control:focus {
    box-shadow: none;
    color: #222;
}

.single-footer-widget ul li {
    margin-bottom: 10px;
}

.single-footer-widget ul li a {
    color: #777777;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single-footer-widget ul li a:hover {
    color: #8490ff;
}

.single-footer-widget .bb-btn {
    /*color: #8490ff;*/
    color: #fff;
    font-weight: 300;
    border-radius: 0;
    z-index: 9999;
    cursor: pointer;
}

.single-footer-widget .info {
    margin-top: 25px;
    color: #fff;
    font-size: 12px;
}

.single-footer-widget .info.valid {
    color: green;
}

.single-footer-widget .info.error{
    color: red;
}

.single-footer-widget .btn {
    font-size: 0.85rem;
    border-radius: 0;
    background: #8490ff;
    color: #fff;
}

.single-footer-widget .btn .lnr {
    line-height: inherit;
    font-size: 13px;
    padding: 0 4px;
}

.single-footer-widget ::-moz-selection {
    /* Code for Firefox */
    background-color: #191919 !important;
    color: #777777;
}

.single-footer-widget ::selection {
    background-color: #191919 !important;
    color: #777777;
}

.single-footer-widget ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #777777;
    font-weight: 300;
}

.single-footer-widget :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #777777;
    opacity: 1;
    font-weight: 300;
}

.single-footer-widget ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #777777;
    opacity: 1;
    font-weight: 300;
}

.single-footer-widget :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #777777;
    font-weight: 300;
}

.single-footer-widget ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #777777;
    font-weight: 300;
}

/*@media (max-width: 991.98px) {
    .single-footer-widget {
        margin-bottom: 30px;
    }
}

.single-footer-widget .footer-social a {
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single-footer-widget .footer-social a i {
    vertical-align: bottom;
    color: #fff;
}

.single-footer-widget .footer-social a:hover i {
    color: #8490ff;
}

.footer-bottom {
    padding-top: 50px;
}

.footer-bottom .footer-social {
    text-align: right;
}

@media (max-width: 991px) {
    .footer-bottom .footer-social {
        text-align: left;
        margin-top: 30px;
    }
}

.footer-bottom .lnr, .footer-bottom a {
    color: #8490ff;
}

.footer-text a {
    color: #8490ff;
}*/

/*-------- end footer Area -------------*/


/*==================responsive========================*/
@media (max-width: 1160px){

    .navbar-header nav ul li a{
        color: #FFFFFF;
    }

    #menu-navbar{
        background: #FFFFFF;
    }

    #logo-side{
        display: block;
        max-width: 200px;
        margin-left: -50px;
    }

    #close-menu{
        display: block;
        position: fixed;
        top:25px;
        right: 30px;
        float: right;
    }

    #side-menu ul {
        padding-bottom: 50px;
    }

    #side-menu ul li{
        display: block;
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    #back-shadow{
        position: fixed;
        z-index: 500;
        top:0;
        bottom: 0;
        right: 0;
        left:0;
        background: darkgrey;
        opacity: 0.7;
    }

    #side-menu{
        position:fixed;
        z-index:1000;
        width:250px;
        background: #3F3F3F;
        top:0;
        bottom:0;
        left:-251px;
        overflow-y: auto;
    }

    #mobile-nav-toggle{
        display: block;
    }

    #mobile-nav-toggle:hover{
        background-color: transparent!important;
        color:black;
    }

    #service-top, #home-about-left{
        text-align: center;
    }

    .banner-right{
        padding-top: 97px;
        padding-left: 0!important;
        padding-right: 0!important;
    }

}


@media (max-width: 768px){
    .img-fluid{
        display: none;
    }

    .img-fluid-responsive{
        display: block;
        width: 100px;
        margin: auto;
        padding-bottom: 20px;
        padding-top: 50px;
    }

    .banner-left{
        display: none;
    }

    .banner-left h1{
        padding-top: 50px;
    }

    .banner-right{
        text-align: center;
    }

    .banner-right h1{
        text-align: center;
    }

    .sous-titre{
        text-align: center;
        font-size: 1.2rem;
    }

    .intro_1{
        padding-top: 90px;
        padding-right:  50px;
        padding-left: 50px;
        display: block;
        top: 250px;
        width: 100%;
    }

    .trophy{
        padding-right: 10px;
        padding-left: 10px;
    }

}